"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
export const Tabs = ({ tabHeaderClassName, activeTabClassName, children, }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const handleTabClick = (tabIndex) => {
        setActiveTabIndex(tabIndex);
    };
    const TabButtons = () => (_jsx("div", { className: "flex", children: children.map((child, index) => (_jsx("div", { className: `${tabHeaderClassName} ${activeTabIndex === index && activeTabClassName}`, onClick: () => handleTabClick(index), children: child.props.title }, index))) }));
    return (_jsxs("div", { children: [_jsx(TabButtons, {}), children[activeTabIndex]] }));
};

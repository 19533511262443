"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
import { Question, X } from "@phosphor-icons/react/dist/ssr";
export const Tooltip = ({ title, children, expandToLeft, ...rest }) => {
    const [show, setShow] = useState(false);
    return (_jsxs("div", { onMouseEnter: () => setShow(true), onMouseLeave: () => setShow(false), ...rest, className: "relative", children: [_jsx("button", { "aria-label": "Tooltip", className: "block", onClick: () => setShow((show) => !show), children: _jsx(Question, { size: 28 }) }), show && (_jsxs("div", { className: classNames("cvr-tooltip sm:max-w-[380px]", {
                    "right-0": expandToLeft,
                }), onClick: () => setShow(false), children: [_jsxs("div", { className: classNames("cvr-tooltip-header-box", {
                            "border-b-0": !children,
                        }), children: [_jsx("h2", { className: "text-lg font-medium", children: title }), _jsx(X, { size: 24 })] }), children && _jsx("div", { className: "p-4", children: children })] }))] }));
};

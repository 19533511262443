"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
export const useCarouselController = ({ threshold = [0.5, 0.75, 1], selector = "li", ...config }) => {
    const [index, setIndex] = useState(0);
    const { ref, onIndex, numberOfItemsPerFrame, count } = useHorizontalScrollController(config);
    const { nextEnabled, previousEnabled } = useMemo(() => {
        return {
            previousEnabled: index !== 0,
            nextEnabled: !ref.current ||
                Math.ceil(ref.current.scrollLeft) !==
                    ref.current.scrollWidth - ref.current.clientWidth,
        };
    }, [index, ref]);
    const onNext = useCallback(() => {
        if (nextEnabled)
            onIndex(index + 1);
    }, [nextEnabled, index, onIndex]);
    const onPrevious = useCallback(() => {
        if (previousEnabled)
            onIndex(index - 1);
    }, [previousEnabled, index, onIndex]);
    useEffect(() => {
        const observers = [];
        if (ref.current)
            ref.current.querySelectorAll(selector).forEach((el) => {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach(({ isIntersecting }) => {
                        if (ref.current && isIntersecting) {
                            const widthPerItem = ref.current.scrollWidth / count;
                            const item = ref.current.scrollLeft / widthPerItem;
                            setIndex(item);
                        }
                    });
                }, { root: ref.current, threshold });
                observer.observe(el);
                observers.push(observer);
            });
        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, [count, ref, threshold, selector]);
    return {
        index: Math.round(index),
        ref,
        onIndex,
        numberOfItemsPerFrame,
        threshold,
        nextEnabled,
        previousEnabled,
        onPrevious,
        onNext,
        ...config,
    };
};
export const useHorizontalScrollController = ({ count, }) => {
    const ref = useRef(null);
    const [numberOfItemsPerFrame, setNumberOfItemsPerFrame] = useState(1);
    useEffect(() => {
        const onResize = () => {
            if (ref.current) {
                const widthPerItem = ref.current.scrollWidth / count;
                const itemsPerClientWidth = ref.current.clientWidth / widthPerItem;
                setNumberOfItemsPerFrame(Math.max(itemsPerClientWidth, 1));
            }
        };
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [count]);
    const onIndex = useCallback((index) => {
        if (ref.current) {
            const widthPerItem = ref.current.scrollWidth / count;
            ref.current.scrollTo(widthPerItem * index, 0);
        }
    }, [count]);
    return {
        numberOfItemsPerFrame,
        ref,
        onIndex,
        count,
    };
};

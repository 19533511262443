"use client";
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useRouter } from "next/navigation";
export const TableRows = ({ data, columns, anchorBase, anchorKey, }) => {
    const router = useRouter();
    const onRowClick = (row) => {
        if (!anchorBase)
            return;
        if (anchorKey)
            router.push(`${anchorBase}${row[anchorKey]}`);
        else
            router.push(anchorBase);
    };
    return (_jsx("tbody", { className: "divide-y", children: data.map((row, rowIndex) => (_jsx("tr", { className: classNames("text-sm font-normal", {
                "hover:cursor-pointer hover:bg-gray-50": anchorKey,
            }), onClick: () => onRowClick(row), role: "link", children: columns.map((column, columnIndex) => (_jsx("td", { className: classNames("px-4 py-3", {
                    hidden: column.hidden,
                }), children: _jsx("span", { className: classNames("whitespace-nowrap"), children: _jsx(_Fragment, { children: row[column.key] }) }) }, `cell-${columnIndex}`))) }, `row-${rowIndex}`))) }));
};

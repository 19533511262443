"use client";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
import { X } from "@phosphor-icons/react/dist/ssr";
import { Dialog, DialogPanel } from "@headlessui/react";
export const Modal = ({ children, className, buttonClassName, buttonText, hasCancel = false, cancelButtonClassName, buttonIcon, cancelButtonText = "Cancel", modalEnabled = true, onButtonClick = () => { }, closeOnBackdropClick = true, disabled = false, }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("button", { className: buttonClassName, onClick: () => {
                    onButtonClick();
                    setIsOpen(true);
                }, disabled: disabled, children: [buttonIcon, buttonText] }), _jsxs(Dialog, { open: isOpen && modalEnabled, onClose: () => {
                    if (closeOnBackdropClick) {
                        setIsOpen(false);
                    }
                }, children: [_jsx("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }), _jsx("div", { className: "fixed inset-0 flex w-screen items-center justify-center p-4", children: _jsxs(DialogPanel, { className: classNames("relative min-h-[250px] min-w-[250px] rounded-xl bg-white px-4 py-6", className), children: [_jsx("button", { onClick: () => setIsOpen(false), className: "absolute right-4 top-6 rounded-full bg-[#F2EFF0] p-2", children: _jsx(X, {}) }), children, hasCancel && (_jsx("button", { onClick: () => setIsOpen(false), className: cancelButtonClassName, children: cancelButtonText }))] }) })] })] }));
};

"use client";

import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { Autocomplete } from "./Autocomplete";
import { ArrowRight } from "@/assets";
import { slugify } from "@/utils/slugify";
import { AuthorHit, BookHit } from "@/search/types";
import { authorIndex, bookIndex, searchClient } from "@/search";
import Link from "next/link";
import Image from "next/image";

/* SearchBar includes a custom Autocomplete component, this is the search box the user writes and searches in.

Inside this file is the configuration of the suggested products that appears in the search menu, ex. suggested books and authors, that are resulting from the search.
Here you can add sources (indexes) to show in the search menu, configure navigation to product pages, how many results showing and how they look like.

See Autocomplete.tsx for the rest of the autocomplete configuration, like the autocomplete instance and plugins configuration, ex. recent searches and query suggestions.  

Followed this guide to build this component: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/with-react-instantsearch/?client=Autocomplete.tsx */
export const SearchBar = () => {
  return (
    <div className="relative flex items-center">
      <Autocomplete
        className={
          "w-full bg-[#EFF0F2] px-4 py-3 sm:w-[25rem] sm:bg-[var(--bg-primary)] sm:px-0 sm:py-0 [&_.aa-Form]:h-8 [&_.aa-Form]:rounded-[1.875rem] [&_.aa-Form]:bg-[#EFF0F2] [&_.aa-Input]:placeholder-[#686B72] [&_.aa-SubmitIcon]:text-[#686B72]"
        }
        searchClient={searchClient}
        placeholder="Søk i alt fra Cappelen Damm"
        detachedMediaQuery="none"
        openOnFocus
        insights
        getSources={({ query }: { query: string }) => [
          {
            sourceId: "books",
            getItemUrl({ item }) {
              // Enables the navigation to book product page with keyboard enter, see navigate()-method in Autocomplete-instance (Autocomplete.tsx) for implementation of navigation
              const bookHit: BookHit = item as BookHit;
              const slug = slugify([
                bookHit.name,
                bookHit.author,
                bookHit.isbn.toString(),
              ]);
              return `/boker/${slug}`;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient: searchClient,
                queries: [
                  {
                    indexName: bookIndex,
                    params: {
                      query,
                      hitsPerPage: 3,
                    },
                  },
                ],
              });
            },
            templates: {
              header(params) {
                return (
                  // TODO: Make this an own component so it can be reused
                  <div>
                    <div className="pb-4">
                      <p className="mt-6 border-b border-[#CACBCE] pb-2">
                        Dette finner vi på “{params?.state?.query}”
                      </p>
                    </div>
                    <div className="flex">
                      <span className="font-semibold">Bøker</span>
                      <Link className="ml-[auto] flex gap-2" href="/search">
                        <span className="underline">Vis alle treff</span>
                        <ArrowRight size={18} />
                      </Link>
                    </div>
                  </div>
                );
              },
              item({ item, components }) {
                const bookHit: BookHit = item as BookHit;
                const slug = slugify([
                  bookHit.name,
                  bookHit.author,
                  bookHit.isbn.toString(),
                ]);
                return (
                  // TODO: Make this an own component so it can be reused
                  <Link
                    tabIndex={0}
                    role="button"
                    style={{ cursor: "pointer" }}
                    href={`/boker/${slug}`} // Navigate to book product page with mouse click
                    className="mb-4 flex cursor-pointer items-center gap-8 px-4 py-2"
                  >
                    <div className="relative flex h-[5.25rem] w-[5.25rem] flex-shrink-0 items-center justify-center rounded bg-[#F7F7F8]">
                      {bookHit.firstImage && (
                        <Image
                          src={bookHit.firstImage}
                          alt="Bilde av boken"
                          className="rounded object-cover"
                          layout="fill"
                          objectFit="contain"
                        />
                      )}
                    </div>

                    <div className="flex flex-col gap-3">
                      <span className="h-5 truncate font-bold">
                        {components.Highlight({
                          hit: bookHit,
                          attribute: ["name"],
                        })}
                      </span>
                      <span className="">
                        {components.Highlight({
                          hit: bookHit,
                          attribute: ["author"],
                        })}
                      </span>
                      <span className="">{bookHit.price},–</span>
                    </div>
                  </Link>
                );
              },
            },
          },
          {
            sourceId: "authors",
            getItemUrl({ item }) {
              // Enables the navigation to author page with keyboard enter, see navigate()-method in Autocomplete-instance (Autocomplete.tsx) for implementation of navigation
              const authorHit: AuthorHit = item as AuthorHit;
              const slug = slugify([authorHit.fullName]);
              return `/forfatter/${slug}`;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient: searchClient,
                queries: [
                  {
                    indexName: authorIndex,
                    params: {
                      query,
                      hitsPerPage: 3,
                    },
                  },
                ],
              });
            },
            templates: {
              header() {
                return (
                  // TODO: Make this an own component so it can be reused
                  <div>
                    <div className="flex">
                      <span className="font-semibold">Forfatter</span>
                    </div>
                  </div>
                );
              },
              item({ item, components }) {
                const authorHit: AuthorHit = item as AuthorHit;
                const slug = slugify([authorHit.fullName]);
                return (
                  // TODO: Make this an own component so it can be reused
                  <Link
                    tabIndex={0}
                    role="button"
                    style={{ cursor: "pointer" }}
                    href={`/forfatter/${slug}`} // Navigate to author page with mouse click
                    className="mb-4 flex cursor-pointer items-center gap-8 px-4 py-2"
                  >
                    <div className="relative flex h-[5.25rem] w-[5.25rem] flex-shrink-0 items-center justify-center rounded bg-[#F7F7F8]">
                      {authorHit.imageURL && (
                        <Image
                          src={authorHit.imageURL}
                          alt="Bilde av forfatteren"
                          className="rounded object-cover"
                          layout="fill"
                          objectFit="contain"
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-3">
                      <span className="h-5 truncate font-bold">
                        {components.Highlight({
                          hit: authorHit,
                          attribute: ["fullName"],
                        })}
                      </span>
                    </div>
                  </Link>
                );
              },
            },
          },
        ]}
      />
    </div>
  );
};
